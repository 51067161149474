import React from "react"
import Select from 'react-select'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Grid } from "../../components/grid/grid"
import { BtnContainedPrimaryIconr } from "../../components/button/button"
import Stepbar from "../../components/stepbar/stepbar"

const options = [
  { value: "Antiga Esquerra de l'Eixample", label: "Antiga Esquerra de l'Eixample" },
  { value: "la Barceloneta", label: "la Barceloneta" },
  { value: "Baró de Viver", label: "Baró de Viver" },
  { value: "Barrio Gòtic", label: "Barrio Gòtic" },
  { value: "el Bon Pastor", label: "el Bon Pastor" },
  { value: "la Bordeta", label: "la Bordeta" },
  { value: "Camp d'en Grassot i Gràcia Nova", label: "Camp d'en Grassot i Gràcia Nova" },
  { value: "Can Baró", label: "Can Baró" },
  { value: "Can Peguera", label: "Can Peguera" },
  { value: "Canyelles", label: "Canyelles" },
  { value: "Ciutat Meridiana", label: "Ciutat Meridiana" },
  { value: "el Clot", label: "el Clot" },
  { value: "la Clota", label: "la Clota" },
  { value: "el Congrés i els Indians", label: "el Congrés i els Indians" },
  { value: "Diagonal Mar i el Front Marítim del Poblenou", label: "Diagonal Mar i el Front Marítim del Poblenou" },
  { value: "la Dreta de l'Eixample", label: "la Dreta de l'Eixample" },
  { value: "El Baix Guinardó", label: "El Baix Guinardó" },
  { value: "El Besós y el Maresme", label: "El Besós y el Maresme" },
  { value: "El Camp de l'Arpa del Clot", label: "El Camp de l'Arpa del Clot" },
  { value: "El Coll", label: "El Coll" },
  { value: "El Fort Pienc", label: "El Fort Pienc" },
  { value: "El Parc i la Llacuna del Poblenou", label: "El Parc i la Llacuna del Poblenou" },
  { value: "El Raval", label: "El Raval" },
  { value: "la Font d'en Fargues", label: "la Font d'en Fargues" },
  { value: "la Font de la Guatlla", label: "la Font de la Guatlla" },
  { value: "el Guinardó", label: "el Guinardó" },
  { value: "la Guineueta", label: "la Guineueta" },
  { value: "Horta", label: "Horta" },
  { value: "Hostafrancs", label: "Hostafrancs" },
  { value: "La Trinitat Nova", label: "La Trinitat Nova" },
  { value: "La Trinitat Vella", label: "La Trinitat Vella" },
  { value: "La Verneda i la Pau", label: "La Verneda i la Pau" },
  { value: "La Vila Olímpica del Poblenou", label: "La Vila Olímpica del Poblenou" },
  { value: "Les Corts", label: "Les Corts" },
  { value: "Les Roquetes", label: "Les Roquetes" },
  { value: "la Marina de Port", label: "la Marina de Port" },
  { value: "la Marina del Prat Vermell", label: "la Marina del Prat Vermell" },
  { value: "Montbau", label: "Montbau" },
  { value: "Montjuic", label: "Montjuic" },
  { value: "Navas", label: "Navas" },
  { value: "Nova Esquerra de l'Eixample", label: "Nova Esquerra de l'Eixample" },
  { value: "Pedralbes", label: "Pedralbes" },
  { value: "les Planes", label: "les Planes" },
  { value: "Porta", label: "Porta" },
  { value: "la Prosperitat", label: "la Prosperitat" },
  { value: "Provençals del Poblenou", label: "Provençals del Poblenou" },
  { value: "Poble Nou", label: "Poble Nou" },
  { value: "Poble Sec", label: "Poble Sec" },
  { value: "Putget i Farró", label: "Putget i Farró" },
  { value: "Putxet", label: "Putxet" },
  { value: "Sagrada Familia", label: "Sagrada Familia" },
  { value: "la Sagrera", label: "la Sagrera" },
  { value: "la Salut", label: "la Salut" },
  { value: "Sant Andreu", label: "Sant Andreu" },
  { value: "Sant Antoni", label: "Sant Antoni" },
  { value: "Sant Genís dels Agudells", label: "Sant Genís dels Agudells" },
  { value: "Sant Gervasi-Galvany", label: "Sant Gervasi-Galvany" },
  { value: "Sant Gervasi-La Bonanova", label: "Sant Gervasi-La Bonanova" },
  { value: "Sant Martí de Provensals", label: "Sant Martí de Provensals" },
  { value: "Sant Pere, Santa Caterina i la Ribera", label: "Sant Pere, Santa Caterina i la Ribera" },
  { value: "Sants", label: "Sants" },
  { value: "Sants-Badal", label: "Sants-Badal" },
  { value: "Sarriá", label: "Sarriá" },
  { value: "Torre Baró", label: "Torre Baró" },
  { value: "les Tres Torres", label: "les Tres Torres" },
  { value: "el Turó de la Peira", label: "el Turó de la Peira" },
  { value: "la Vall d'Hebron", label: "la Vall d'Hebron" },
  { value: "Vallbona", label: "Vallbona" },
  { value: "Vallcarca i els Penitents", label: "Vallcarca i els Penitents" },
  { value: "Vallvidrera, el Tibidabo i les Planes", label: "Vallvidrera, el Tibidabo i les Planes" },
  { value: "Verdún", label: "Verdún" },
  { value: "Vila de Gràcia", label: "Vila de Gràcia" },
  { value: "Vilapicina - Torre Llobeta", label: "Vilapicina - Torre Llobeta" },
  { value: "Zona Universitaria de Barcelona", label: "Zona Universitaria de Barcelona" },
];

export default class DownloadPage extends React.Component {
  
  state = { 
    location: "Barcelona",
    timesforwork: "0",
    timesforfree: "0",
    selectedOption: null,
    minutestofind: "0",
    losttime: "",
    parkforwork: "",
    parkforleisure: "",
    parkpreference: "",
    notcarpark: "",
    acceptcarpark: "",
    parknewareas: "",
    cost: "0",
    apptosearch: "",
    currentapp: "",
    pollution: "",
    fuelwaste: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  
  render() {

    const location = this.state.location;
    let locationInfo;

    if(location !== "Barcelona"){
      locationInfo = <p class="margintop1 body-grey3">Responde las siguientes preguntas según tus hábitos de aparcamiento en la ciudad de Barcelona, no en tu localidad.</p>;
    }

    const parkpreference = this.state.parkpreference;
    let notcarpark;
    let acceptcarpark;

    if((parkpreference === "Zona azul / verde") || (parkpreference === "Calle gratis")){
      notcarpark = <div class="margintop1-5">
      <p>¿Por qué no prefieres aparcar en parkings?</p>
      <div class="inputWrap">
          <input type="radio" id="notcarpark-streetisbetter" name="notcarpark" value="Aparcar en la calle es más cómodo" onChange={this.handleInputChange}/>
          <label for="notcarpark-streetisbetter">Aparcar en la calle es más cómodo.</label>
      </div>

      <div class="inputWrap">
          <input type="radio" id="notcarpark-dontfind" name="notcarpark" value="No suelo encontrar parkings disponibles" onChange={this.handleInputChange}/>
          <label for="notcarpark-dontfind">No suelo encontrar parkings disponibles.</label>
      </div>

      <div class="inputWrap">
          <input type="radio" id="notcarpark-price" name="notcarpark" value="Precios elevados" onChange={this.handleInputChange}/>
          <label for="notcarpark-price">Precios elevados.</label>
      </div>

      <div class="inputWrap">
          <input type="radio" id="notcarpark-other" name="notcarpark" value="Otra razón" onChange={this.handleInputChange}/>
          <label for="notcarpark-other">Otra razón.</label>
      </div>
    </div>;
    }

    if((parkpreference === "Zona azul / verde") || (parkpreference === "Calle gratis")){
      acceptcarpark = <div class="margintop1-5">
      <p>Si te guiamos a un parking con plazas disponibles más cerca de tu destino, para que no tengas que dar vueltas y andes menos, ¿lo elegirías frente a la zona azul/verde?</p>
      <div class="inputWrap">
          <input type="radio" id="acceptcarpark-yes" name="acceptcarpark" value="Sí" onChange={this.handleInputChange}/>
          <label class="labelradio" for="acceptcarpark-yes">Sí.</label>
      </div>

      <div class="inputWrap">
          <input type="radio" id="acceptcarpark-no" name="acceptcarpark" value="No" onChange={this.handleInputChange}/>
          <label class="labelradio" for="acceptcarpark-no">No.</label>
      </div>
    </div>;
    }

    const apptosearch = this.state.apptosearch;
    let currentapp;

    if(apptosearch === "Sí"){
      currentapp = <div class="margintop1-5">
      <p>¿Qué app utilizas?</p>
      <input type="text" name="currentapp" value={this.state.currentapp} onChange={this.handleInputChange}/>
    </div>
    }

    return (
      <Layout>
      <SEO 
      title="Sobre tus hábitos de aparcamiento"
      />

        <Container>
          <Grid>
            <div class="l-start-3 l-end-11 m-start-2 m-end-6">
              
              <Stepbar 
                step1="Paso 1"
                step1Class="active"
              />
              <h1>Sobre tus hábitos de aparcamiento:</h1>

              <form id="poll"
              action="https://formspree.io/mvokeang"
              method="POST">

                <div>
                  <p>Lugar de residencia:</p>
                  <select  name="location" value={this.state.location} onChange={this.handleInputChange}>
                    <option>Barcelona</option>
                    <option disabled>──────────</option>
                    <option>Badalona</option>
                    <option>Cardedeu</option>
                    <option>Castellbisbal</option>
                    <option>Centellas</option>
                    <option>El Prat de Llobregat</option>
                    <option>Esparreguera</option>
                    <option>Girona</option>
                    <option>Granollers</option>
                    <option>Igualada</option>
                    <option>L'Hospitalet de Llobregat</option>
                    <option>Mataró</option>
                    <option>Molins de Rei</option>
                    <option>Mollet del Valles</option>
                    <option>Reus</option>
                    <option>Rubi</option>
                    <option>Sabadell</option>
                    <option>Sant Cugat del Valles</option>
                    <option>Sant Pere de Ribes</option>
                    <option>Terrassa</option>
                    <option>Vilafranca del Penedes</option>
                    <option>Vilanova i la Geltru</option>
                    <option>Vilassar de Dalt</option>
                    <option disabled>──────────</option>
                    <option>Otra localidad</option>
                  </select>
                </div>
                {locationInfo}

                <div class="margintop1-5">
                  <p>¿Cuántas veces al mes aparcas en Barcelona para ir al trabajo/estudio?</p>
                  <div class="rangevalue">{this.state.timesforwork} veces</div>
                  <input type="range" min="0" max="45" name="timesforwork" value={this.state.timesforwork} onChange={this.handleInputChange}/>
                </div>
        
                <div class="margintop1-5">
                  <p>¿Cuántas veces al mes aparcas en Barcelona por ocio o recados puntuales?</p>
                  <div class="rangevalue">{this.state.timesforfree} veces</div>
                  <input type="range" min="0" max="45" name="timesforfree" value={this.state.timesforfree} onChange={this.handleInputChange}/>
                </div>

                <div class="margintop1-5">
                  <p>¿En qué barrios sueles aparcar? Puedes seleccionar varios</p>
                  <select  multiple name="neighborhood" class="select-multiple-mobile">
                    <option>Antiga Esquerra de l'Eixample</option>
                    <option>la Barceloneta</option>
                    <option>Baró de Viver</option>
                    <option>Barrio Gòtic</option>
                    <option>el Bon Pastor</option>
                    <option>la Bordeta</option>
                    <option>Camp d'en Grassot i Gràcia Nova</option>
                    <option>Can Baró</option>
                    <option>Can Peguera</option>
                    <option>Canyelles</option>
                    <option>Ciutat Meridiana</option>
                    <option>el Clot</option>
                    <option>la Clota</option>
                    <option>el Congrés i els Indians</option>
                    <option>Diagonal Mar i el Front Marítim del Poblenou</option>
                    <option>la Dreta de l'Eixample</option>
                    <option>El Baix Guinardó</option>
                    <option>El Besós y el Maresme</option>
                    <option>El Camp de l'Arpa del Clot</option>
                    <option>El Coll</option>
                    <option>El Fort Pienc</option>
                    <option>El Parc i la Llacuna del Poblenou</option>
                    <option>El Raval</option>
                    <option>la Font d'en Fargues</option>
                    <option>la Font de la Guatlla</option>
                    <option>el Guinardó</option>
                    <option>la Guineueta</option>
                    <option>Horta</option>
                    <option>Hostafrancs</option>
                    <option>La Trinitat Nova</option>
                    <option>La Trinitat Vella</option>
                    <option>La Verneda i la Pau</option>
                    <option>La Vila Olímpica del Poblenou</option>
                    <option>Les Corts</option>
                    <option>Les Roquetes</option>
                    <option>la Marina de Port</option>
                    <option>la Marina del Prat Vermell</option>
                    <option>Montbau</option>
                    <option>Montjuic</option>
                    <option>Navas</option>
                    <option>Nova Esquerra de l'Eixample</option>
                    <option>Pedralbes</option>
                    <option>les Planes</option>
                    <option>Porta</option>
                    <option>la Prosperitat</option>
                    <option>Provençals del Poblenou</option>
                    <option>Poble Nou</option>
                    <option>Poble Sec</option>
                    <option>Putget i Farró</option>
                    <option>Putxet</option>
                    <option>Sagrada Familia</option>
                    <option>la Sagrera</option>
                    <option>la Salut</option>
                    <option>Sant Andreu</option>
                    <option>Sant Antoni</option>
                    <option>Sant Genís dels Agudells</option>
                    <option>Sant Gervasi-Galvany</option>
                    <option>Sant Gervasi-La Bonanova</option>
                    <option>Sant Martí de Provensals</option>
                    <option>Sant Pere, Santa Caterina i la Ribera</option>
                    <option>Sants</option>
                    <option>Sants-Badal</option>
                    <option>Sarriá</option>
                    <option>Torre Baró</option>
                    <option>les Tres Torres</option>
                    <option>el Turó de la Peira</option>
                    <option>la Vall d'Hebron</option>
                    <option>Vallbona</option>
                    <option>Vallcarca i els Penitents</option>
                    <option>Vallvidrera, el Tibidabo i les Planes</option>
                    <option>Verdún</option>
                    <option>Vila de Gràcia</option>
                    <option>Vilapicina - Torre Llobeta</option>
                    <option>Zona Universitaria de Barcelona</option>
                  </select>
                </div>

                <Select
                  className="select-multiple-desktop"
                  closeMenuOnSelect={false}
                  isMulti
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  placeholder="Selecciona los barrios"
                  name="neighborhood"
                />

                <div class="margintop1-5">
                  <p>¿Cuántos minutos sueles tardar en encontrar aparcamiento?</p>
                  <div class="rangevalue">{this.state.minutestofind} minutos</div>
                  <input type="range" min="0" max="45" name="minutestofind" value={this.state.minutestofind} onChange={this.handleInputChange}/>
                </div>
                
                <div class="margintop1-5">
                  <p>¿Te importa perder ese tiempo?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="losttime-yes" name="losttime" value="Sí" onChange={this.handleInputChange}/>
                      <label for="losttime-yes">Sí.</label>
                    </div>
                    
                    <div class="inputWrap">
                      <input type="radio" id="losttime-no" name="losttime" value="No" onChange={this.handleInputChange}/>
                      <label for="losttime-no">No.</label>
                    </div>
                </div>


                <div class="margintop1-5">
                  <p>¿En qué tipo de aparcamiento sueles aparcar para ir al trabajo/estudio?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="parkforwork-streetfree" name="parkforwork" value="Calle gratis" onChange={this.handleInputChange}/>
                      <label for="parkforwork-streetfree">Calle, gratis.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="parkforwork-carpark" name="parkforwork" value="Parking cubierto" onChange={this.handleInputChange}/>
                      <label for="parkforwork-carpark">Parking cubierto.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="parkforwork-streetpay" name="parkforwork" value="Zona azul / verde" onChange={this.handleInputChange}/>
                      <label for="parkforwork-streetpay">Zona azul / verde.</label>
                    </div>
                </div>
                
                <div class="margintop1-5">
                  <p>¿En qué tipo de aparcamiento sueles aparcar de forma puntual, por ocio o recados?</p>
                  <div class="inputWrap">
                      <input  type="radio" id="parkforleisure-streetfree" name="parkforleisure" value="Calle gratis" onChange={this.handleInputChange}/>
                      <label for="parkforleisure-streetfree">Calle, gratis.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="parkforleisure-carpark" name="parkforleisure" value="Parking cubierto" onChange={this.handleInputChange}/>
                      <label for="parkforleisure-carpark">Parking cubierto.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="parkforleisure-streetpay" name="parkforleisure" value="Zona azul / verde" onChange={this.handleInputChange}/>
                      <label for="parkforleisure-streetpay">Zona azul / verde.</label>
                    </div>
                </div>

                <div class="margintop1-5">
                  <p>¿Dónde prefieres aparcar?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="parkpreference-carpark" name="parkpreference" value="Parking cubierto" onChange={this.handleInputChange}/>
                      <label for="parkpreference-carpark">Parking cubierto.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="parkpreference-streetpay" name="parkpreference" value="Zona azul / verde" onChange={this.handleInputChange}/>
                      <label for="parkpreference-streetpay">Zona azul / verde.</label>
                    </div>
                </div>

                {notcarpark}
                {acceptcarpark}
      

                <div class="margintop1-5">
                  <p>¿Con qué frecuencia visitas zonas que desconoces donde aparcar?</p>

                  <div class="inputWrap">
                    <input  type="radio" id="parknewareas-daily" name="parknewareas" value="Diariamente" onChange={this.handleInputChange}/>
                    <label for="parknewareas-daily">Diariamente.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="parknewareas-usually" name="parknewareas" value="Muchas veces" onChange={this.handleInputChange}/>
                    <label for="parknewareas-usually">Muchas veces.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="parknewareas-sometimes" name="parknewareas" value="A veces" onChange={this.handleInputChange}/>
                    <label for="parknewareas-sometimes">A veces.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="parknewareas-rarely" name="parknewareas" value="Pocas veces" onChange={this.handleInputChange}/>
                    <label for="parknewareas-rarely">Pocas veces.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="parknewareas-never" name="parknewareas" value="Nunca" onChange={this.handleInputChange}/>
                    <label for="parknewareas-never">Nunca.</label>
                  </div>
                </div>


                <div class="margintop1-5">
                  <p>¿Cuánto sueles gastar por aparcar al día?</p>
                  <div class="rangevalue">{this.state.cost} €</div>
                  <input type="range" min="0" max="30" name="cost" value={this.state.cost} onChange={this.handleInputChange}/>
                </div>

                <div class="margintop1-5">
                  <p>¿Utilizas alguna app que te ayude a encontrar aparcamiento?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="apptosearch-yes" name="apptosearch" value="Sí" onChange={this.handleInputChange}/>
                      <label for="apptosearch-yes">Sí.</label>
                    </div>
                    
                    <div class="inputWrap">
                      <input type="radio" id="apptosearch-no" name="apptosearch" value="No" onChange={this.handleInputChange}/>
                      <label for="apptosearch-no">No.</label>
                    </div>
                </div>

                {currentapp}

                
                <div class="margintop1-5">
                  <p>¿Te preocupa la contaminación generada por tu coche?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="pollution-yes" name="pollution" value="Sí" onChange={this.handleInputChange}/>
                      <label for="pollution-yes">Sí.</label>
                    </div>
                    
                    <div class="inputWrap">
                      <input type="radio" id="pollution-no" name="pollution" value="No" onChange={this.handleInputChange}/>
                      <label for="pollution-no">No.</label>
                    </div>
                </div>

                <div class="margintop1-5">
                  <p>¿Eres consciente del malgasto en combustible generado por dar vueltas buscando aparcamiento?</p>
                    <div class="inputWrap">
                      <input  type="radio" id="fuelwaste-yes" name="fuelwaste" value="Sí, y me preocupa" onChange={this.handleInputChange}/>
                      <label for="fuelwaste-yes">Sí, y me preocupa.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="fuelwaste-yesbutnotcare" name="fuelwaste" value="Sí, pero no me importa" onChange={this.handleInputChange}/>
                      <label for="fuelwaste-yesbutnotcare">Sí, pero no me importa.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="fuelwaste-no" name="fuelwaste" value="No, y me preocupa" onChange={this.handleInputChange}/>
                      <label for="fuelwaste-no">No, y me preocupa.</label>
                    </div>

                    <div class="inputWrap">
                      <input type="radio" id="fuelwaste-nobutnotcare" name="fuelwaste" value="Sí, pero no me importa" onChange={this.handleInputChange}/>
                      <label for="fuelwaste-nobutnotcare">No, pero no me importa.</label>
                    </div>
                    
                </div>
                <div class="margintop2">
                  <h3>¡Ya casi lo tienes!</h3>
                  <p class="body-large">Un último paso para <strong>descargar la app</strong>.</p>
                </div>

                <div class="margintop1">
                  <input type="hidden" name="_next"
                  value="https://parquick.com/descargar/formulario-2"
                  />
                  <BtnContainedPrimaryIconr
                  icon="icon-next_24">
                    Continuar
                  </BtnContainedPrimaryIconr>
                </div>
                
                
              </form>

            </div>
          </Grid>
        </Container>
      </Layout>
    )
  }
}