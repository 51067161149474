import React from "react"

import stepbarStyles from "./stepbar.module.scss"


const Stepbar = props => (
    <div className={stepbarStyles.stepbar}>
        <div className={stepbarStyles.step + ' ' + stepbarStyles[props.step1Class]}>
            <div className={stepbarStyles.title}>
                {props.step1}
            </div>
        </div>
        <div className={stepbarStyles.step + ' ' + stepbarStyles[props.step2Class]}>
            <div className={stepbarStyles.title}>
                {props.step2}
            </div>
        </div>
    </div>
)


export default Stepbar